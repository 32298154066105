<template>
  <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-gray-800 text-xl font-bold">{{$t('settings')}}</h6>
        <button @click="save" class="rouanded focus:outline-none outline-none text-gray-100 px-3 w-1/6 py-2 bg-green-600  hover:shadow-inner hover:bg-green-700  transition-all duration-300" type="button">{{$t('Save settings')}}</button>
      </div>
    </div>
    <div v-if="currentUser.type && currentUser.type != 'Seller'" class="flex-auto px-4 lg:px-10 py-10 pt-0 relative">
      <loading :active.sync="loading" :is-full-page="fullPage"></loading>
        <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">{{$t('order_fees')}}</h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="grid-password">{{$t('confirmation_fees')}} <sup v-if="idWharhouse">({{idWharhouse.currency}})</sup></label>
              <input type="number" v-model="formData.detail.order.feesConfirmation" min="0" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150">
            </div>
          </div>
           <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="fulfillement_fees">{{$t('fulfillement_fees')}} <sup v-if="idWharhouse">({{idWharhouse.currency}})</sup></label>
              <input type="number" v-model="formData.detail.order.fulfillement_fees" min="0" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150">
            </div>
          </div>
          <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="fulfillement_fees">{{$t('upsell fees')}} <sup v-if="idWharhouse">({{idWharhouse.currency}})</sup></label>
              <input type="number" v-model="formData.detail.order.upsell" min="0" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150">
            </div>
          </div>
        </div>
        <hr class="mt-6 border-b-1 border-gray-400">
        <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">{{$t('shipping_fees')}}</h6>
        <div class="flex flex-wrap">
            <div class="w-full lg:w-4/12 px-4">
               <div class="relative w-full mb-3">
                  <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="grid-password">{{$t('shipping_fees')}} <sup v-if="idWharhouse">({{idWharhouse.currency}})</sup></label>
                  <input v-if="coutryW!='MA'" type="number" v-model="formData.detail.shipping.fees" min="0" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150">
                  <!-- fees Morroc-->
                  <div v-else class="bg-white shadow-md rounded-lg px-3 py-2 mb-4 overflow-y-auto max-height-300">
                         <div  v-for="(zon, index) in cities" :key="index" class="flex justify-center items-center border-dotted border-4 border-light-blue-500 justify-start cursor-pointer text-gray-700 hover:text-blue-400 hover:bg-blue-100 rounded-md px-2 py-2 my-2">
                           
                            <div class="flex-grow w-4/5 font-medium px-2">
                              <span v-for="(city, ind) in zon._id.cities" :key="ind" class="m-1 whitespace-nowrap bg-gray-200 hover:bg-gray-300 rounded-full px-2 font-bold text-sm leading-loose cursor-pointer">{{city}}</span>
                            </div>
                            <div class="py-1 w-1/5 h-8 flex justify-center items-center px-2 rounded-full text-xs bg-green-500 text-white cursor-pointer font-medium">{{zon._id.price}}</div>
                        </div>
                  </div>
                </div>
            </div>
            <div class="w-full lg:w-4/12 px-4">
               <div class="relative w-full mb-3">
                  <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="grid-password">{{$t('return_fees')}} <sup v-if="idWharhouse">({{idWharhouse.currency}})</sup></label>
                  <input type="number" v-model="formData.detail.shipping.refund" min="0" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150">
                </div>
            </div>
            <div class="w-full lg:w-4/12 px-4">
               <div class="relative w-full mb-3">
                  <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="grid-password">{{$t('cancelation_fees')}} <sup v-if="idWharhouse">({{idWharhouse.currency}})</sup></label>
                  <input type="number" v-model="formData.detail.shipping.cancelation" min="0" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150">
                </div>
            </div>
        </div>
         <hr class="mt-6 border-b-1 border-gray-400">
          <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">{{$t('quality_control')}}</h6>
          <div class="flex flex-wrap">
            <div class="w-full lg:w-4/12 px-4">
              <div class="relative w-full mb-3">
                <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="grid-password">{{$t('quality_control')}} <sup v-if="idWharhouse">({{idWharhouse.currency}})</sup></label>
                <input type="number" v-model="formData.detail.quality_control" min="0" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150">
              </div>
            </div>
        </div>
         <hr class="mt-6 border-b-1 border-gray-400">
          <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">conversion</h6>
          <div class="flex flex-wrap">
            <div class="w-full lg:w-1/2 px-4">
              <div class="relative w-full mb-3">
                <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="grid-password">conversion orders</label>
                <div class="flex">
                  <div class="w-1/2 border hover:border-green-500  hover:border-green-500  focus-within:border-blue-500 flex flex-wrap items-stretch relative h-15 bg-white items-center rounded">
                      <div class="flex -mr-px justify-center p-1 rounded shadow ">
                         <span class="flex border border-black items-center leading-normal bg-white px-3 border-0 rounded rounded-r-none text-sm font-bold text-gray-600">
                            <sup class="uppercase"> MAD </sup><span class="select-none ml-2"><img src="https://static.dwcdn.net/css/flag-icons/flags/4x3/ma.svg" class="flag-img"></span>
                         </span>
                      </div>
                      <input type="number" v-model="formData.detail.info.mad" disabled min="1" class="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border-0 h-10 border-grey-light rounded text-sm shadow rounded-l-none px-3 self-center relative outline-none">
                  </div>
                  <i class="material-icons mx-2 items-center flex-imp">arrow_forward</i>
                  <div class="w-1/2 border hover:border-green-500  hover:border-green-500  focus-within:border-blue-500 flex flex-wrap items-stretch  relative h-15 bg-white items-center rounded">
                      <div class="flex -mr-px justify-center p-1 rounded  shadow ">
                         <span class="flex border border-black items-center leading-normal bg-white px-3 border-0 rounded rounded-r-none text-sm font-bold text-gray-600">
                            <sup class="uppercase" v-if="idWharhouse"> {{idWharhouse.currency}} </sup><span class="select-none ml-2"><img :src="idWharhouse?idWharhouse.flag:''" class="flag-img"></span>
                         </span>
                      </div>
                      <input type="number" v-model="formData.detail.info.currency" min="0" class="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border-0 h-10 border-grey-light rounded text-sm shadow rounded-l-none px-3 self-center relative outline-none">
                    </div>
                </div>
              </div>
            </div>
            <div class="w-full lg:w-1/2 px-4">
              <div class="relative w-full mb-3">
                <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="grid-password">conversion crédits</label>
                <div class="flex">
                  <div class="w-1/2 border hover:border-green-500  hover:border-green-500  focus-within:border-blue-500 flex flex-wrap items-stretch relative h-15 bg-white items-center rounded">
                      <div class="flex -mr-px justify-center p-1 rounded shadow ">
                         <span class="flex border border-black items-center leading-normal bg-white px-3 border-0 rounded rounded-r-none text-sm font-bold text-gray-600">
                            <sup class="uppercase"> MAD </sup><span class="select-none ml-2"><img src="https://static.dwcdn.net/css/flag-icons/flags/4x3/ma.svg" class="flag-img"></span>
                         </span>
                      </div>
                      <input type="number" v-model="formData.detail.info.mad" disabled min="1" class="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border-0 h-10 border-grey-light rounded text-sm shadow rounded-l-none px-3 self-center relative outline-none">
                  </div>
                  <i class="material-icons mx-2 items-center flex-imp">arrow_forward</i>
                  <div class="w-1/2 border hover:border-green-500  hover:border-green-500  focus-within:border-blue-500 flex flex-wrap items-stretch  relative h-15 bg-white items-center rounded">
                      <div class="flex -mr-px justify-center p-1 rounded  shadow ">
                         <span class="flex border border-black items-center leading-normal bg-white px-3 border-0 rounded rounded-r-none text-sm font-bold text-gray-600">
                            <sup class="uppercase" v-if="idWharhouse"> {{idWharhouse.currency}} </sup><span class="select-none ml-2"><img :src="idWharhouse?idWharhouse.flag:''" class="flag-img"></span>
                         </span>
                      </div>
                      <input type="number" v-model="formData.detail.info.currencyCredit" min="0" class="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border-0 h-10 border-grey-light rounded text-sm shadow rounded-l-none px-3 self-center relative outline-none">
                    </div>
                </div>
              </div>
            </div>
        </div>
         <hr class="mt-6 border-b-1 border-gray-400">
          <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">{{$t('Notifications')}}</h6>
          <div class="flex flex-wrap">
            <div class="w-full lg:w-4/12 px-4">
              <div class="relative w-full mb-3">
                <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="grid-password">{{$t('phone')}}</label>
                <input type="text" v-model="formData.detail.phone"  class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150">
              </div>
            </div>
            <div class="w-full lg:w-4/12 px-4">
              <div class="relative w-full mb-3">
                <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="grid-password">{{$t('Email notifications')}}</label>
                <input type="email" v-model="formData.detail.email"  class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150">
              </div>
            </div>
           </div>

         <!-- <hr class="mt-6 border-b-1 border-gray-400">
        <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">{{$t('tva')}}</h6>
        <div class="flex flex-wrap">
          <div
                  class="border border-gray-400 lg:w-4/12 hover:border-blue-400 focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1"
                >
                  <div
                    class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
                  >
                    <p>
                      <label for="cities" class="block  bg-gray-200 uppercase text-gray-700 text-xs font-bold mb-2"
                        >{{$t('Tva list')}}<span :class="$colors.required">
                          *</span
                        ></label
                      >
                    </p>
                  </div>
                  <div
                    id="tva"
                    class="md:w-full overflow-y-auto h-full flex flex-col items-center p-1"
                  >
                    <div class="w-full">
                      <div class="flex flex-col items-center relative">
                        <div class="w-full">
                          <div
                            class="mt-2 shadow  mb-2 p-1 bg-white flex border border-gray-200 rounded"
                          >
                            <div class="flex flex-auto flex-wrap"></div>
                            <input
                              v-model="tvaInput"
                              @keyup.enter="trigger"
                              placeholder="Enter tva (%)"
                              class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
                            />
                            <div
                              class="text-gray-300 w-8 py-1 pl-2 pr-1 border-l flex items-center border-gray-200"
                            >
                              <button
                                @click="addTva"
                                ref="sendReply"
                                class="cursor-pointer w-6 h-6 text-blue-500 outline-none focus:outline-none"
                              >
                                <i class="material-icons">add</i>
                              </button>
                            </div>
                          </div>
                        </div>
                      
                        <div
                          v-if="formData.tva.length > 0"
                          class="w-full max-height-200"
                        >
                          <div
                            v-for="(t, index) in formData.tva"
                            :key="index"
                            class="flex cursor-pointer my-1 hover:bg-blue-lightest rounded"
                          >
                              <div class="w-8 h-10 text-center py-1 hori-center">
                                <p class="text-3xl p-0 text-grey-dark">•</p>
                              </div>
                              <div class="w-4/5 h-10 py-3 px-1">
                                <p class="hover:text-blue-dark">{{t}} %</p>
                              </div>
                              <div class="w-1/5 h-10 text-right p-3">
                                <p  @click="deleteTva(index)"  class="btn-close vs-chip--close" data-role="remove"></p>
                              </div>
                        
                          </div>
                        </div>
                        <div v-else class="py-2">
                          <span
                            class="w-full flex justify-center text-center border-gray-200 text-base"
                            ><p
                              class="font-semibold flex text-blue-500 whitespace-no-wrap"
                            >
                              <span>No Tva added </span>
                            </p></span
                          >
                        </div>
                       
                      </div>
                    </div>
                  </div>
                </div>
        </div> -->
        <hr class="mt-6 border-b-1 border-gray-400">
        <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">Vérification SMS</h6>
        <div class="flex flex-wrap">
          <div class="w-1/6 px-4">
               <div class="relative w-full">
                  <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="grid-password">{{$t("Activation")}} </label>
                  <p class="flex  w-full py-2">
                      <!-- Toggle Button -->
                      <label for="toogleS" class="flex items-center cursor-pointer">
                          <div class="relative">
                            <input id="toogleS"  type="checkbox" class="hidden" v-model="formData.sms.active" />
                            <div class="toggle__line w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
                            <div :class="'toggle__dot-1'" class="absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 left-0"></div>
                          </div>
                    </label>
                  </p>
                </div>
            </div>
            <div v-if="formData.sms.active" class="w-5/6 px-4">
               <div class="relative w-full">
                  <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="grid-password">{{$t("Type d'utilisateur")}} </label>
                  <v-select  label="name"  v-model="formData.sms.roles" :options="roles" multiple class="px-3 py-2 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"></v-select>
                </div>
            </div>
        </div>
        
        <hr class="mt-6 border-b-1 border-gray-400">
        <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">Blacklist phone number</h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full my-3">
             <div class="border  border-gray-500  transition-all duration-500 relative rounded p-1">
                  <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                    <p><label for="cities" class="bg-gray-200 text-gray-700 px-1  uppercase text-xs font-bold">Add phone <img v-if="idWharhouse && idWharhouse.flag" class="h-4 w-5 inline-block ml-2" :src="idWharhouse.flag"   /></label></p>
                  </div>
                  <div id="city" class="md:w-full overflow-y-auto h-full flex flex-col items-center p-1">
                    <div class="w-full">
                      <div class="flex flex-col items-center relative">
                        <div class="w-full">
                          <div class="mt-2 mb-4 p-1 bg-white flex border border-gray-200 rounded">
                            <div class="flex flex-auto flex-wrap"></div>
                            <input
                              v-model="phoneInput"
                              @keyup.enter="trigger"
                              placeholder="Enter phone"
                              class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
                            />
                            <div class="text-gray-300 w-8 py-1 pl-2 pr-1 border-l flex items-center border-gray-200">
                              <button @click="addPhones" ref="sendReply" class="cursor-pointer w-6 h-6 text-blue-500 outline-none focus:outline-none"><i class="material-icons">add</i></button>
                            </div>
                          </div>
                        </div>
                        <!-- Displaying cities -->
                        <div
                          v-if="formData.blacklist_phones.length > 0"
                          class="tag_cities"
                        >
                          <span
                            v-for="(city, index) in formData.blacklist_phones"
                            :key="index"
                            class="tag label label-info"
                          >
                            <span class="text-chip vs-chip--text">{{
                              city
                            }}</span>
                            <span
                              class="btn-close vs-chip--close"
                              @click="deletePhone(index)"
                              data-role="remove"
                            ></span>
                          </span>
                        </div>
                        <div v-else class="py-2">
                          <span
                            class="w-full flex justify-center text-center border-gray-200 text-base"
                            ><p
                              class="font-semibold flex text-blue-500 whitespace-no-wrap"
                            >
                              <span>No Phone added </span>
                            </p></span
                          >
                        </div>
                        <!-- / Displaying cities -->
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>

       

    </div>
    <div v-if="currentUser.type && currentUser.type == 'Seller'" class="flex-auto px-4 lg:px-10 py-10 pt-0 relative">
        <loading :active.sync="loading" :is-full-page="fullPage"></loading>
        <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">{{$t('API')}}</h6>
        <div class="flex flex-wrap">
          <div class="w-full md:w-1/2 xl:w-1/2 px-4">
               <div class="ant-col">
                <div class="ant-row">
                  <div class="ant-label">
                    <label for="key" class="ant-form" title="identité du client">API key</label>
                  </div>
                  <div class="ant-col ant-form-item">
                    <div class="ant-form-item-control has-success">
                      <span class="ant-form-item-children">
                        <span class="ant-input-group-wrapper">
                          <span class="ant-input-group"><input disabled="" id="key" class="ant-input ant-input-disabled"  type="text" :value="currentUser.api?currentUser.api.key:''">
                            <span @click="copyIt('key')" class="ant-input-group-addon">
                              <svg aria-hidden="true" data-prefix="fal" data-icon="copy" class="svg-inline--fa fa-copy fa-w-14 Icon__StyledIcon-sc-1pfgdmr-0  hahmov" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" color="#5c6166"><path fill="currentColor" d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM352 32.491a15.88 15.88 0 0 1 7.431 4.195l51.882 51.883A15.885 15.885 0 0 1 415.508 96H352V32.491zM288 464c0 8.822-7.178 16-16 16H48c-8.822 0-16-7.178-16-16V144c0-8.822 7.178-16 16-16h80v240c0 26.51 21.49 48 48 48h112v48zm128-96c0 8.822-7.178 16-16 16H176c-8.822 0-16-7.178-16-16V48c0-8.822 7.178-16 16-16h144v72c0 13.2 10.8 24 24 24h72v240z"></path></svg>
                            </span>
                          </span>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <div class="w-full md:w-1/2 xl:w-1/2 px-4">
               <div class="ant-col">
                <div class="ant-row">
                  <div class="ant-label">
                    <label for="secret" class="ant-form" title="identité du client">API secret key</label>
                  </div>
                  <div class="ant-col ant-form-item">
                    <div class="ant-form-item-control has-success">
                      <span class="ant-form-item-children">
                        <span class="ant-input-group-wrapper">
                          <span @click="copyIt('secret')" class="ant-input-group"><input disabled="" id="secret" class="ant-input ant-input-disabled"  type="text" :value="currentUser.api?currentUser.api.secret:''">
                            <span  class="ant-input-group-addon">
                              <svg aria-hidden="true" data-prefix="fal" data-icon="copy" class="svg-inline--fa fa-copy fa-w-14 Icon__StyledIcon-sc-1pfgdmr-0  hahmov" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" color="#5c6166"><path fill="currentColor" d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM352 32.491a15.88 15.88 0 0 1 7.431 4.195l51.882 51.883A15.885 15.885 0 0 1 415.508 96H352V32.491zM288 464c0 8.822-7.178 16-16 16H48c-8.822 0-16-7.178-16-16V144c0-8.822 7.178-16 16-16h80v240c0 26.51 21.49 48 48 48h112v48zm128-96c0 8.822-7.178 16-16 16H176c-8.822 0-16-7.178-16-16V48c0-8.822 7.178-16 16-16h144v72c0 13.2 10.8 24 24 24h72v240z"></path></svg>
                            </span>
                          </span>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>  
        <hr class="mt-6 border-b-1 border-gray-400">
        <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">Warehouses (Country code)</h6>
        <div class="container tag-rail">
         
          <div class="rail-container">
            <div class="rail" id="rail">
               <span v-for="(item, index) in currentUser.countries" :key="index" :id="'tag'+index" class="tag">  {{ item.name }} (<b class="text-black">{{ item.code }}</b>)</span>
            </div>
          </div>
        
        </div>
        <hr class="mt-6 border-b-1 border-gray-400">
        <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">Prefix Order ID</h6>
        <div class="flex flex-wrap">
            <div v-for="(item) in currentUser.prefixs" :key="item.country" class="w-full lg:w-4/12 px-4"  >
              <div  class="border hover:border-green-500  hover:border-green-500  focus-within:border-blue-500 flex flex-wrap items-stretch w-full mb-2 relative h-15 bg-white items-center rounded">
                <div class="flex -mr-px justify-center p-1">
                  <span class="flex border border-black items-center leading-normal bg-white px-3 border-0 rounded rounded-r-none text-sm font-bold text-gray-600">
                      <span class="select-none mr-2"> <img  class="flag-img" :src="$f.getCountryCode(item.country)"/></span> <span>{{ `${item.name}_0000X` }}</span>
                  </span>
                </div>
                <input type="text" placeholder="....."  v-model="item.name" class="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border-0 h-10 border-grey-light rounded rounded-l-none px-3 self-center relative outline-none">
              </div>
            </div>
        </div>   
        <!-- <hr class="mt-6 border-b-1 border-gray-400">
        <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">{{$t('packages')}}</h6>
        <div class="flex flex-wrap">
           <div class="w-full lg:w-4/12 px-4">
             <div class="relative w-full mb-3">
             <label for="authorized_open_package" class="block uppercase text-gray-700 text-xs font-bold mb-2">{{$t('authorized_open_package')}}</label>
                
                <label for="toogleA" class="flex items-center cursor-pointer">
                  <div class="relative">
                      <input id="toogleA" type="checkbox" class="hidden" v-model="formData.detailSeller.open_colis" />
                      <div class="toggle__line w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
                      <div class="toggle__dot absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 left-0"></div>
                  </div>
                    <div class="ml-3 text-gray-700">{{ formData.detailSeller.open_colis  }}</div>
                </label>

              </div>
            </div>
        </div> -->
        <hr class="mt-6 border-b-1 border-gray-400">
        <h6 class="text-gray-500 mb-10 text-sm mt-3  font-bold uppercase">Services</h6>
         <div class="flex flex-col lg:flex-row">
            <div class="intro-y flex-1 box py-8 cursor-pointer zoom-in" :class="{active: formData.detailSeller.confirmation}">
               <div class="check"><span class="feather-icon select-none relative"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check w-5 h-5 text-white bg-green-500 rounded-full shadow"><polyline points="20 6 9 17 4 12"></polyline></svg></span></div>
               
                <svg class="feather feather-shopping-bag block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" ><path d="M3.848 19h-.848c-.796 0-1.559-.316-2.121-.879-.563-.562-.879-1.325-.879-2.121v-3c0-7.175 5.377-13 12-13s12 5.825 12 13v3c0 .796-.316 1.559-.879 2.121-.562.563-1.325.879-2.121.879h-.848c-2.69 4.633-6.904 5-8.152 5-1.248 0-5.462-.367-8.152-5zm16.152-5.876c-.601.236-1.269-.18-1.269-.797 0-.304-.022-.61-.053-.915-1.761-.254-3.618-1.926-3.699-3.723-1.315 2.005-4.525 4.17-7.044 4.17 1.086-.699 1.839-2.773 1.903-3.508-.581 1.092-2.898 3.136-4.551 3.487l-.018.489c0 .619-.669 1.032-1.269.797v3.771c.287.256.632.464 1.041.594.225.072.412.224.521.424 2.206 4.046 5.426 4.087 6.438 4.087.929 0 3.719-.035 5.877-3.169-1.071.433-2.265.604-3.759.653-.37.6-1.18 1.016-2.118 1.016-1.288 0-2.333-.784-2.333-1.75s1.045-1.75 2.333-1.75c.933 0 1.738.411 2.112 1.005 1.9-.026 4.336-.334 5.888-2.645v-2.236zm-11-.624c.686 0 1.243.672 1.243 1.5s-.557 1.5-1.243 1.5-1.243-.672-1.243-1.5.557-1.5 1.243-1.5zm6 0c.686 0 1.243.672 1.243 1.5s-.557 1.5-1.243 1.5-1.243-.672-1.243-1.5.557-1.5 1.243-1.5zm5.478-1.5h1.357c-.856-5.118-4.937-9-9.835-9-4.898 0-8.979 3.882-9.835 9h1.357c.52-4.023 3.411-7.722 8.478-7.722s7.958 3.699 8.478 7.722z"/></svg>
                <div class="text-xl font-medium text-center mt-10">Confirmation</div>
               <!-- <div class="text-gray-600 dark:text-gray-400 px-10 text-center mx-auto mt-2"> Lorem Ipsum is simply dummy text of the printing and typesetting industry. </div>
                <div class="flex justify-center"><div class="relative text-5xl font-semibold mt-8 mx-auto"><span class="absolute text-2xl top-0 left-0 text-gray-600 -ml-4">$</span> 120 </div></div>-->
                <ul class="text-center mt-4">
                  <li><a href="#" class="font-semibold"><i class="material-icons"  style="font-size: 13px;">fiber_manual_record</i> {{$t('Confirmation fees')}} : {{formData.detail.order.feesConfirmation}} <sup v-if="idWharhouse">({{idWharhouse.currency}})</sup></a></li>
                </ul>
            </div>
            <div class="intro-y flex-1 box py-8 lg:ml-5 mb-5 lg:mb-0 cursor-pointer zoom-in" :class="{active: formData.detailSeller.fulfillement}">
                <div class="check"><span class="feather-icon select-none relative"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check w-5 h-5 text-white bg-green-500 rounded-full shadow"><polyline points="20 6 9 17 4 12"></polyline></svg></span></div>
                
                 <svg  xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill-rule="evenodd" clip-rule="evenodd"  class="feather feather-shopping-bag block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto"><path d="M6 23h-3v-10l8.991-8.005 9.009 8.005v10h-3v-9h-12v9zm1-2h10v2h-10v-2zm0-3h10v2h-10v-2zm10-3v2h-10v-2h10zm-5-14.029l12 10.661-1.328 1.493-10.672-9.481-10.672 9.481-1.328-1.493 12-10.661z"/></svg>
                <div class="text-xl font-medium text-center mt-10">Warehouse</div>
                <!-- <div class="text-gray-600 dark:text-gray-400 px-10 text-center mx-auto mt-2"> Lorem Ipsum is simply dummy text of the printing and typesetting industry. </div>
                <div class="flex justify-center"><div class="relative text-5xl font-semibold mt-8 mx-auto"><span class="absolute text-2xl top-0 left-0 text-gray-600 -ml-4">$</span> 120 </div></div>-->
               <ul class="text-center mt-4">
                  <li><a href="#" class="font-semibold"><i class="material-icons" style="font-size: 13px;">fiber_manual_record</i> {{$t('Fulfillement fees')}} : {{formData.detail.order.fulfillement_fees}} <sup v-if="idWharhouse">({{idWharhouse.currency}})</sup></a></li>
                </ul>
            </div>
            <div class="intro-y flex-1 box py-8 lg:ml-5 cursor-pointer zoom-in" :class="{active: formData.detailSeller.shipping}">
                <div class="check"><span class="feather-icon select-none relative"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check w-5 h-5 text-white bg-green-500 rounded-full shadow"><polyline points="20 6 9 17 4 12"></polyline></svg></span></div>
                <!-- <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-bag block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto"><path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path><line x1="3" y1="6" x2="21" y2="6"></line><path d="M16 10a4 4 0 0 1-8 0"></path></svg> -->
                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24"  class="feather feather-shopping-bag block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto"><path d="M15.744 16.683l.349-.199v1.717l-.349.195v-1.713zm3.414-.227l.342-.196v-1.717l-.343.195v1.718zm-1.429.813l.343-.195v-1.717l-.343.195v1.717zm.578-.329l.349-.199v-1.717l-.349.199v1.717zm-1.152.656l.343-.196v-1.717l-.343.196v1.717zm-.821.467l.343-.195v-1.717l-.343.195v1.717zm6.666-11.122v11.507l-9.75 5.552-12.25-6.978v-11.507l9.767-5.515 12.233 6.941zm-12.236-4.643l-2.106 1.19 8.891 5.234-.002.003 2.33-1.256-9.113-5.171zm1.236 10.59l-9-5.218v8.19l9 5.126v-8.098zm3.493-3.056l-8.847-5.208-2.488 1.405 8.86 5.138 2.475-1.335zm5.507-.696l-7 3.773v8.362l7-3.985v-8.15z"/></svg>
                <div class="text-xl font-medium text-center mt-10">Shipping</div>
           <!-- <div class="text-gray-600 dark:text-gray-400 px-10 text-center mx-auto mt-2"> Lorem Ipsum is simply dummy text of the printing and typesetting industry. </div>
                <div class="flex justify-center"><div class="relative text-5xl font-semibold mt-8 mx-auto"><span class="absolute text-2xl top-0 left-0 text-gray-600 -ml-4">$</span> 120 </div></div>-->
                <ul  class="text-center mt-4">
                  <li v-if="coutryW!='MA'"><a href="#" class="font-semibold"><i class="material-icons"  style="font-size: 13px;">fiber_manual_record</i> {{$t('Shipping fees')}} : {{formData.detail.shipping.fees}}<sup v-if="idWharhouse">({{idWharhouse.currency}})</sup></a></li>
                  <li><a href="#" class="font-semibold"><i class="material-icons"  style="font-size: 13px;">fiber_manual_record</i> {{$t('Return fees')}} : {{formData.detail.shipping.refund}} <sup v-if="idWharhouse">({{idWharhouse.currency}})</sup></a></li>
                  <li><a href="#" class="font-semibold"><i class="material-icons"  style="font-size: 13px;">fiber_manual_record</i> {{$t('Cancelation fees')}} : {{formData.detail.shipping.cancelation}} <sup v-if="idWharhouse">({{idWharhouse.currency}})</sup></a></li>
                </ul>
            </div>
         </div>
    </div>
    <div class="pt-3 flex btn-bottom-toolbar justify-end">
      <button @click="save" class="rounded focus:outline-none outline-none text-gray-100 px-3 w-1/6 py-2 bg-green-600  hover:shadow-inner hover:bg-green-700  transition-all duration-300"> {{$t('Save settings')}} </button>
    </div>
  </div>
</template>

<script>
var phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
const PNF = require('google-libphonenumber').PhoneNumberFormat;
export default {
  props: {
     currentUser: { type: Object},
     idWharhouse: { type: Object},
   },
  data() {
    return {
      formData: {
        details:[],
        dataUser:[],
        detailSeller:{
          open_colis:false,
          confirmation:false,
          fulfillement:false,
          shipping:false,
        },
         detail:{
          shipping: {
          fees: 0,
          },
          order: {
            feesConfirmation: 0,
            fulfillement_fees: 0,
          },
          quality_control:0, 
          phone:null,email:null,
          info: {mad: 1,currency: 1,currencyCredit: 1,},
      },
        tva:[],
        blacklist_phones:[],
        sms:{active:false,roles:[]}
      },
      detail:{
          shipping: {
          fees: 0,
          },
          order: {
            feesConfirmation: 0,
            fulfillement_fees: 0,
          },
          quality_control:0,
          phone:null,email:null,
          info: {mad: 1,currency: 1,currencyCredit: 1},
      },
      detailSeller:{
         open_colis:false,
         confirmation:false,
         fulfillement:false,
         shipping:false,
      },
      loading:false,
      fullPage: false,
      tvaInput:"",
      currency:"",
      options:this.$countries,
      cities:[],
      coutryW:"",
      phoneInput:"",
      roles:[]
    };
  },

  async mounted() {
     await this.getSettings();
     await this.getCities();
  },
  computed: {
    warhouseSelected(){
      return this.$store.getters["wharhouse/warhouseSelected"]({user:_.pick(this.currentUser,['countries'])})
    },
  },
   watch: {
      'warhouseSelected': async function(oldVal, newVal) {
          await this.getSettings();
          await this.getCities();
      },
      'formData.sms.active': async function(oldVal, newVal) {
          console.log('newVal--------',oldVal)
          if(this.roles.length == 0 && oldVal && this.currentUser.type != 'Seller')  await this.getRoles();
      }
  },
  methods: {
    trigger() {
      this.$refs.sendReply.click();
    },
    async getRoles() {
      const res = await this.$server.search("roles",{limit:10});
       if (res.content.results) {
        this.roles = res.content.results;
      } else this.roles = [];
    },
    copyIt: function(id) {
        //console.log('id-----',id)
        document.getElementById(id).removeAttribute('disabled');  
        let copyInput = document.querySelector('#'+id);
      //  console.log('copueee-----',copyInput)
        copyInput.select();
        document.execCommand("copy");
        document.getElementById(id).setAttribute('disabled', true);
    },
     addPhones(index) {
      if (this.phoneInput === "" || this.phoneInput === null || this.phoneInput.value === 0) {
        alert(`Please enter phone in text box below !! `, "warning");
      }else {
        console.log('phoneInput---------',this.phoneInput)
        console.log('this.idWharhouse.country---------',this.idWharhouse.country)
         let tel = phoneUtil.parse(this.phoneInput, this.idWharhouse.country);
         tel=phoneUtil.format(tel, PNF.E164);
        this.formData.blacklist_phones.push(tel);
        this.phoneInput = "";
      }
    },
    deletePhone(index) {
      this.formData.blacklist_phones.splice(index, 1);
    },
     async getUser() {
      const res = await this.$server.me("users");
      //console.log(res);
      if (res.content) this.currentUser = res.content;
      else this.currentUser = {};
    },
    async getCities(){
      const resCities = await this.$server.get("zones", { allCitiesAndFees: true,country:await this.warhouseSelected}); 
          if(resCities.content.length>0 && await this.warhouseSelected==="MA")  this.cities= resCities.content;
          else this.cities=[];
    },
    async getCurrency(){
      await this.$store.dispatch('wharhouse/getCurrency').then((res) => {
            this.currency=res;
        })
    },
     getElementInArray(data,value){
        var result  = data.filter(function(o){
            let whar=o.warehouse;
            if(o.warehouse._id) whar=o.warehouse._id;
            return whar == value.toString();
        } );
        return result? result[0] : null; // or undefined
    },
    addTva() {
      if (this.tvaInput === "" || this.tvaInput === null || this.tvaInput.value === 0) {
        alert(`Please enter tva in text box below !! `, "warning");
      } else if(isNaN(this.tvaInput)){
            alert(`Please enter a number value`, "warning");
      }else {
        
        this.formData.tva.push(this.tvaInput);
        this.tvaInput = "";
        console.log('tvaaa____',this.formData)
      }
    },
    deleteTva(index) {
      this.formData.tva.splice(index, 1);
    },
    async getwharhouseId(){
      await this.$store.dispatch('wharhouse/getwharhouseId',{country:await this.warhouseSelected}).then((res) => {
            this.idWharhouse=res;
        })
    },
    async changeData(data,warhouse, element) {
        for (var i in data) {
          if (data[i].warehouse == warhouse.toString()) {
             data[i] = element;
             break; //Stop this loop, we found it!
          }
        }
     },
    async save() {
      //get data current warhouse
       if(this.currentUser.type != "Seller"){
            const dataWahrhouse = await this.$server.get("settings",{warehouse:this.idWharhouse._id});
            if(dataWahrhouse.content.length>0) {
                this.formData.action="Update";
            }else{
                this.formData.action="Add";
            }
            this.formData.detail.warehouse=this.idWharhouse._id;
            this.formData.detail.country=this.idWharhouse.country;
            const data = await this.$server.update("settings", this.formData);
            if (data && data._id) {
              await this.getSettings();
              alert(this.$t('setting_updated'), "success");
            }
       }else{
            // const userData = await this.$server.get("users", {id: this.currentUser._id,});
            // this.formData.dataUser=userData.content;
            // const dataWahrhouse = await this.$server.get("users",{id:this.currentUser._id,warehouseSettings:this.idWharhouse._id});
            // if(dataWahrhouse.content.length>0){
            //    this.changeData(this.formData.dataUser.settings,this.idWharhouse._id,this.formData.detailSeller)
            // }else{
            //   this.formData.detailSeller.warehouse=this.idWharhouse._id;
            //    this.formData.dataUser.settings.push(this.formData.detailSeller);
            // }

            //const data = await this.$server.update("users",this.formData.dataUser);
            const data = await this.$server.update('users', {_id:this.currentUser._id,prefixs:this.currentUser.prefixs.filter(x => x.name)});
            if (data && data._id) {
              await this.getSettings();
              alert(this.$t('setting_updated'), "success");
            }
       }
    },
    async getSettings() {
        this.coutryW=await this.warhouseSelected
        this.loading=true;
        const data = await this.$server.get("settings");
        this.loading=false; 
        const dataWahrhouse = await this.$server.get("settings",{warehouse:this.idWharhouse._id});
        this.formData.tva=data.content.tva;
        this.formData._id=data.content._id;
        this.formData.details=data.content.details;
        if(!data.content.blacklist_phones) this.formData.blacklist_phones=[];
        else  this.formData.blacklist_phones=data.content.blacklist_phones;

        if(!data.content.sms) this.formData.sms={active:false,roles:[]};
        else this.formData.sms=data.content.sms;

        if(dataWahrhouse.content.length>0){
            const currentSettingW= this.getElementInArray(dataWahrhouse.content[0].details,this.idWharhouse._id);
            if(!currentSettingW.info) currentSettingW.info= {mad: 1,currency: 1,currencyCredit: 1};
            this.formData.detail = currentSettingW;
        }else this.formData.detail = {shipping: {fees: 0,},order: {feesConfirmation: 0, fulfillement_fees: 0},quality_control:0,phone:null,email:null,info: {mad: 1,currency: 1,currencyCredit: 1},};

        if(this.currentUser.type === "Seller"){
            //const dataWahrhouse = await this.$server.get("users",{id:this.currentUser._id,warehouseSettings:this.idWharhouse._id});
            // if(dataWahrhouse.content.length>0){
            //     const currentSettingW= this.getElementInArray(dataWahrhouse.content[0].settings,this.idWharhouse._id);
            //     this.formData.detailSeller = currentSettingW;
            // }else this.formData.detailSeller = {open_colis:false,confirmation:false,fulfillement:false,shipping:false,};

            const currentSettingW= this.getElementInArray(this.currentUser.settings,this.idWharhouse._id);
            if(!currentSettingW) this.formData.detailSeller = {open_colis:false,confirmation:false,fulfillement:false,shipping:false,};
            else this.formData.detailSeller = currentSettingW;

            if(!this.currentUser.prefixs) this.currentUser.prefixs=[];
            this.currentUser.countries.forEach(arg => {
                var index = this.currentUser.prefixs.findIndex(x => x.country === arg.code)
                if (index === -1) this.currentUser.prefixs.push({name:"",country:arg.code});
            });

        }else{
          if(this.formData.sms.active) await this.getRoles();
        }
        
    },
  },
};
</script>

<style scoped>
.tag-rail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  padding: 0;
  white-space: nowrap;
}
.rail-container {
  overflow: hidden;
  height: 36px;
  padding-top: 6px;
  cursor: pointer;
  scroll-behavior: smooth;
}
.tag {
  background: #e3ebf7;
  border-radius: 8px;
  padding: 3px 8px 4px;
  margin: 5px 4px;
  color: #109d93;
  font-weight: 500;
  user-select: none;
  border: 1px solid #e3ebf7;
  transition-duration: 0.25s;
  white-space: nowrap;
  border: 2px dashed #067d75;
}
.tag:first-child {
  margin-left: 16px;
}
.tag:last-child {
  margin-right: 16px;
}
.tag:hover {
  background: #dce4f0;
}
.tag:active {
  background: #e3ebf7;
}
.tag-selected {
  background: #3b71ca;
  color: #fff;
  border: 1px solid #3b71ca;
}
.tag-selected:hover {
  background: #3265b9;
  color: #fff;
}
.tag-selected:active {
  background: #3b71ca;
  color: #fff;
}
[class*="toggle__dot-"] {
  top: -.25rem;
  left: -.25rem;
  transition: all 0.3s ease-in-out;
}

input:checked ~ [class*="toggle__dot-"] {
  transform: translateX(100%);
  background-color: #63b3ed;
}
.toggle__dot {
  top: -.25rem;
  left: -.25rem;
  transition: all 0.3s ease-in-out;
}
.flex-imp {
    display: flex !important;
}
input:checked ~ .toggle__dot {
  transform: translateX(100%);
  background-color: #63b3ed;
}
.hori-center{
  display: flex;
    justify-content: center;
    align-items: center;
}
.vs-chip--close {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 0;
    margin: 0 4px;
    margin-left: 8px;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.15);
    color: #fff;
    transition: all 0.3s ease;
}
.vs-chip--close:after {
    content: "x";
    padding: 0px 2px;
    margin-bottom: 3px;
    font-size: 15px;
}
.whitespace-nowrap{
  	white-space: nowrap;
}
.max-height-200{
  max-height: 200px;
}
.max-height-300{
  max-height: 300px;
}
.tag_cities{
  max-height: 19vh;
  overflow-y: scroll;
}
/* total width */
.tag_cities::-webkit-scrollbar {
    width:7px
}


/* scrollbar itself */
.tag_cities::-webkit-scrollbar-thumb {
    background-color:#babac0;
    border-radius:16px;
}
.dtag_cities::-webkit-scrollbar-thumb:hover {
    background-color:#a0a0a5;
    border:4px solid #f4f4f4
}

/* set button(top and bottom of the scrollbar) */
.dropdown_warhouse::-webkit-scrollbar-button {display:none}
</style>